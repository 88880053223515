.task {
	color: black;
	padding: 0.4em;
	border-color: gray;
	border-style: solid;
	border-width: 1px;
	border-radius: 4px;
	margin: 0.3em;
	background-color: rgba(255, 255, 255, 0.70);
	display: grid;
}

.task .pill-row {
	display: flex;
	margin-top: 4px;
	justify-content: flex-end;
}

.task .pill-row .pill {
	border-radius: 5px;
	padding: 4px;
	font-size: xx-small;
	font-weight: bold;
	text-transform: uppercase;
}

.task .pill-row .pill.period {
	background-color: darkgray;
}

.task .content, .task .done {
	grid-area: 1 / 1;
}

.task .done {
	display: flex;
	justify-content: center;
	align-items: center;
	transform: rotate(-45deg);
	color: green;
	font-size: x-large;
	font-weight: bolder;
}

.task .title {
	font-weight: bolder;
}

.task.dragging {
	box-shadow:
    0 0 10px 5px rgb(186, 216, 255)  /* inner white */
}